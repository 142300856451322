import ReactDOM from 'react-dom/client';
import App from './App';
import {
    BrowserRouter,
    Routes,
    Route
}from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path = "/" element = {<App />}></Route>
        </Routes>
    </BrowserRouter>
)